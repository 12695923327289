body{
    box-sizing: border-box;
    overflow-x: hidden;
}

:root{
    --primary-color : #006874;
    --primary-color-light : #EDF5F6;
    --white : #fff;
}

#menu-icon{
    width: 50px;
    height: 50px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#menu-icon:hover{
    background: linear-gradient(0deg, rgba(3, 31, 35, 0.08), rgba(3, 31, 35, 0.08));
    opacity: 100%;
    padding: 10px;
    transition: background 0.3s;
    border-radius: 50%;
}

.btn.btn-outline-success-dark{
    color: var(--primary-color);
    background-color: var(--white);
    border-color: var(--primary-color);
}

.btn:hover.btn-outline-success-dark {
    color: var(--primary-color-light);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: var(--primary-color-light);
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    max-height: 100%;
    min-height: 100vh;
}

#sidebar.active {
    margin-left: -250px;
}

.text-primary{
    color: var(--primary-color) !important;
}

.bg-success-dark{
    background-color: var(--primary-color)!important;
}

.bg-success-dark i{
    cursor: pointer;
}